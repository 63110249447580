import React from 'react'
import './Navigation.css'
function Navigation() {
  return (
    <div className='navigation-container bg-black sm:bg-transparent  hover:bg-black ease-in-out duration-500'>
      <div className='w-full'>
        <a href="#home"><img src="logo-alt2.png" className="logo pt-4" alt="Glass Artery Logo" /></a>
      </div>
      <div className='nav-options w-full m-auto mt-2 space-x-8 text-center ease-in-out duration-500 sm:mt-4 sm:opacity-0'>
        <a href="#about"><button className='text-white text-sm w-1/4 p-1 br rounded-sm ease-in-out duration-500 hover:text-black hover:bg-gray-300 sm:w-48 md:text-lg'><strong>ABOUT</strong></button></a>
        <a href="#shows"><button className='text-white text-sm w-1/4 p-1 br rounded-sm ease-in-out duration-500 hover:text-black hover:bg-gray-300 sm:w-48 md:text-lg'><strong>SHOWS</strong></button></a>
      </div>
    </div>
  )
}

export default Navigation