import React from 'react'
import './Home.css'
import Section from '../components/Section'

function Home() {
  return (
    <Section className="bg-transparent">
      <a id="home" className='opacity-0'>Glass Artery</a>
      <div className="home-container">
         <video id="bg-video" preload='auto' autoPlay playsInline muted loop src="https://glassartery.blob.core.windows.net/website/Allclipsnoimg.mp4">
           <source src="https://glassartery.blob.core.windows.net/website/Allclipsnoimg.mp4" type="video/mp4" />
        </video>
          <div className="m-auto justify-center flex">
            <iframe className="bandcamp-media" src="https://bandcamp.com/EmbeddedPlayer/album=2971137921/size=large/bgcol=333333/linkcol=0f91ff/tracklist=true/transparent=true/" seamless><a href="https://glassartery.bandcamp.com/album/eternal-return">Eternal Return by Glass Artery</a></iframe>
          </div>
      </div>
    </Section>
  )
}

export default Home