import Navigation from './components/Navigation';
import About from './sections/About';
import Home from './sections/Home';
import Showcase from './sections/Showcase';
import './App.css';
import Footer from './components/Footer';

function App() {
  return (
    <div className='app-container'>
      <Navigation />
      <Home />
      <About />
      <Showcase />
      <Footer />
    </div>
  );
}

export default App;
