import React from 'react'
import "./Section.css"
function Section({bgClass, hrefId, children}) {
  return (
    <div className={`section-container ${bgClass ? bgClass : "bg-transparent"}`}>
      <a id={hrefId}></a>
      {children}
    </div>
  )
}

export default Section