import React, { useEffect, useState } from 'react'
import "./BandsInTownWidget.css";
import { faCaretDown, faCaretUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';


const BandsInTownTourDate = ({tourDate, isPastTour}) => {
  const USAShow = "United States";
  const DateInfo = new Date(tourDate.datetime);
  const MonthNames =  ["Jan", "Febr", "Mar", "Apr", "May", "Jun",
  "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
  const Days = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"]
  const TicketUrl = tourDate.url;
  const Venue = tourDate.venue.name;
  const Region = tourDate.venue.country === USAShow ? (tourDate.venue.region) : (tourDate.venue.country);
  const City = tourDate.venue.city;
  const DayofWeek = Days[DateInfo.getDay()];
  const Month = MonthNames[DateInfo.getMonth()];
  const DateNumber = DateInfo.getDate();
  const OutOfStock = tourDate.offers.length > 0 ? tourDate.offers[0].status === "sold out" : false;
  const SoldOutClass = OutOfStock ? "bitw-custom-sold-out bitw-custom-buy-tickets" : "bitw-custom-buy-tickets";
  const TicketText = isPastTour ? "I Was There" : 
                      tourDate.offers.length === 0 ? "RSVP" : 
                      tourDate.offers[0].status === "sold out" ? "Sold Out" : "Buy Tickets";


  return (
    <tr className="bitw-elm-bg">
      <td className="bitw-td bitw-td-date">
        {DayofWeek} {Month} {DateNumber}
      </td>
      <td className="bitw-td bitw-td-venue-and-location">
        <p>
          <span className="bitw-outer-venue">
            <span className="bitw-inner-venue">
              {Venue}
            </span>
          </span>
        </p>
        <p>
        <span className="bitw-outer-location">
          <span className="bitw-inner-location">
              {City}, {Region}
          </span>
        </span>
      </p>
    </td>
    <td className="bitw-td bitw-td-tickets">
      <span className="bitw-buy-tickets">
        <span className={SoldOutClass}>
          <a href={TicketUrl} target="_blank" rel="noreferrer">{TicketText}</a>
        </span>
      </span>
    </td>
    </tr>
  );
};

function BandsInTownWidget() {
  const [tourDates, setTourDates] = useState([]);
  const [pastDates, setPastDates] = useState([]);
  const [displayPastShows, setDisplayPastShows] = useState(false);
  
  const toggleDisplayPastShows = () => {
    setDisplayPastShows(state => !state)
  }

  useEffect(() => {
    // fetch('https://rest.bandsintown.com/artists/Phish/events?app_id=Test')
    fetch("https://rest.bandsintown.com/V3.1/artists/id_15527118/events?app_id=js_www.glassartery.com")
      .then(response => response.json())
      .then(data => setTourDates(data))
      // fetch('https://rest.bandsintown.com/artists/Phish/events?app_id=Test')
    fetch("https://rest.bandsintown.com/V3.1/artists/id_15527118/events?app_id=js_www.glassartery.com&date=past")
      .then(response => response.json())
      .then(data => setPastDates(data))
  }, []);

  const tourDateItems = tourDates.map((tourDate) => {
    return <BandsInTownTourDate
    tourDate = {tourDate}
    key= {tourDate.id} />
  });

  const pastDateItems = pastDates.map((pastDate) => {
    return <BandsInTownTourDate
    tourDate = {pastDate}
    isPastTour
    key= {pastDate.id} />
  });

  return(
    <div className= "bitw-container">
      <h2 className='text-center mt-4 mb-2 text-4xl md:text-5xl md:my-3'><strong>Tour Info</strong></h2>
      <h3 className='text-xl text-center bg-black p-2 lg:p-4'>
          <strong>Upcoming Shows</strong>
      </h3>
      <div className="bitw-custom-table-container">
        {
          tourDateItems.length ?
          (
          <table className="bitw-custom-table">
            <tbody>
            {tourDateItems}
            </tbody>
          </table>
          ) : (
            <div className='text-center text-md px-[10px] py-2 lg:text-2xl lg:py-4 bitw-elm-bg'>
              <div>No upcoming shows yet, follow us on Bandsintown for updates!</div>
              <div className='my-2 m-auto justify-center flex'>
                <a href="https://www.bandsintown.com/artist-subscribe/15527118" target="_blank" rel="noreferrer">
                  <button className='bitw-button'>
                    <svg xmlns="http://www.w3.org/2000/svg" width="18px" height="18px" viewBox="0 0 24 24" style={{margin: "6px 10px 6px 0px"}}><path fill="#FFFFFF" fill-rule="evenodd" d="M18.783 0H24v12h-5.217V0zm-6.261 5h5.217v7h-5.217V5zM6.26 5h5.217v7H6.261V5zM24 24H0V0h5.217v19h13.566v-1H6.26v-5H24v11z"></path></svg>
                    follow
                  </button>
                </a>
              </div>
            </div>
          )
        }
      </div>

      <h3 className='text-xl text-center bg-black p-2 lg:p-4'>
        <button onClick={toggleDisplayPastShows}>
          <strong>Past Shows</strong>
          <FontAwesomeIcon icon={faCaretDown} className={`bitw-icon ${displayPastShows ? "" : "bitw-hide"}`}/>
          <FontAwesomeIcon icon={faCaretUp} className={`bitw-icon ${displayPastShows ? "bitw-hide" : ""}`}/>
        </button>
      </h3>
      <div className={`bitw-custom-table-container ${displayPastShows ? "bitw-hide" : ""}`}>
        <table className="bitw-custom-table">
          <tbody>
          {pastDateItems}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default BandsInTownWidget