import React from 'react'
import Section from '../components/Section'
import BandsInTownWidget from '../components/BandsInTownWidget'

function Showcase() {
  return (
    <Section hrefId="shows">
      <div>
        <BandsInTownWidget />
      </div>
    </Section>
  )
}

export default Showcase