import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faYoutube, faInstagram, faFacebook, faBandcamp, faSpotify} from '@fortawesome/free-brands-svg-icons';
import './Footer.css';

function Footer() {
  return (
    <div className="footer">
      <div>
        <h2 className='text-lg sm:text-xl footer-header'><strong>Follow Us</strong></h2>
      </div>
      <div>
        <a href="https://www.youtube.com/channel/UC48MCKUMBvvuoPY261njnLg">
          <FontAwesomeIcon icon={faYoutube} className='footer-icon'/>
        </a>
        <a href="https://instagram.com/glassartery">
          <FontAwesomeIcon icon={faInstagram} className='footer-icon'/>
        </a>
        <a href="https://www.facebook.com/glassarterynyc">
          <FontAwesomeIcon icon={faFacebook} className='footer-icon'/>
        </a>
        <a href="https://glassartery.bandcamp.com">
          <FontAwesomeIcon icon={faBandcamp} className='footer-icon'/>
        </a>
        <a href="https://open.spotify.com/artist/5FnkbPR9VtePDOlhzfE2BS">
          <FontAwesomeIcon icon={faSpotify} className='footer-icon'/>
        </a>
      </div>
      <div>
        <p className='text-xs sm:text-sm mb-3'>© 2024 Glass Artery</p>
      </div>
    </div>
  )
}

export default Footer