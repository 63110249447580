import React from 'react'
import "./About.css";
import Section from '../components/Section';

function About() {
  return (
    <Section bgClass="bg-black" hrefId="about">
      <div className='about-container'>
        <div className='about-panel'>
          <iframe
            title="Instagram Widget"
            className="instagram-media" 
            src="https://www.instagram.com/glassartery/embed/?cr=1&amp;rd=https%3A%2F%2Fwww.glassartery.com">
          </iframe>
        </div>
        <div className='about-panel'>
          <div className='about-bio'>
            <h2 className='mx-5 text-md md:text-xl lg:text-xl border-b-white border-b-2'><strong>Band Info</strong></h2>
            <p className='mx-5 text-sm md:mt-2 md:text-md lg:text-lg xl:mt-3'>
              Eclectic music that is the almaganation of Post Metal, Progressive Metal, Atmospheric Black Metal, Post Rock, Ambient, and more hailing from Brooklyn, NY.
            </p>
          </div>
        </div>
      </div>
    </Section>
  )
}

export default About